import React from 'react';

const Button = ({ text, onClick }) => {
    return (
        <button onClick={onClick} className='bg-red-600 px-4 py-2 text-white'>
            {text}
        </button>
    );
};

export default Button;