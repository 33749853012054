import React from 'react';

const BannerSection = ({ title, text, image }) => {
    return (
        <div className="relative w-full min-h-48 sm:h-64 md:h-96">
            {/* Background Image */}
            <div
                className={`absolute inset-0 bg-cover bg-center transition-all duration-300 ${image ? 'bg-gradient-to-t from-black to-transparent' : 'bg-gray-300'}`}
                style={{ backgroundImage: image ? `url(${image})` : 'none' }}
            ></div>

            {/* Overlay to create a gradient effect */}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>

            {/* Content */}
            <div className="relative z-10 flex flex-col items-center py-5 justify-center text-center space-y-3 sm:space-y-5 text-white min-h-48 sm:h-full  px-4">
                <h1 className="text-xl sm:text-3xl md:text-5xl font-bold mb-1 sm:mb-2">{title}</h1>
                <p className="text-sm sm:text-base md:text-xl max-w-md sm:max-w-3xl">{text}</p>
            </div>
        </div>
    );
};

export default BannerSection;
