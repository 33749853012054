import React, { useRef, useState } from 'react';
import Button from './Button';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const form = useRef();
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ucplaeq', 'template_aoqzrsv', form.current, 'zEaNLH7LN43tv-Q42')
            .then((result) => {
                console.log(result.text);
                setMessage('¡Mensaje enviado con éxito!');
                e.target.reset();
            }, (error) => {
                console.log(error.text);
                setMessage('Hubo un error al enviar el mensaje. Por favor, intenta nuevamente.');
            });
    };

    return (
        <div className="max-w-lg mx-auto p-8">
            <form ref={form} onSubmit={sendEmail}>
                <div className="mb-4">
                    <input
                        type="text"
                        id="name"
                        name="user_name"
                        className="w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Nombre"
                        required
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="email"
                        id="email"
                        name="user_email"
                        className="w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Email"
                        required
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="tel"
                        id="phone"
                        name="user_phone"
                        className="w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Teléfono"
                    />
                </div>
                <div className="mb-4">
                    <textarea
                        id="message"
                        name="message"
                        rows="4"
                        className="w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Consulta"
                        required
                    ></textarea>
                </div>

                <Button type="submit" text='Enviar' />
            </form>
            {message && <p className="mt-4 text-center text-green-500">{message}</p>}
        </div>
    );
};

export default ContactForm;
