import React from 'react';
import heroPhoto from '../assets/HERO.webp';
import Button from '../components/Button';
import menuPDF from '../assets/menu.pdf';

function Hero() {

    const handleClick = () => {
        window.open(menuPDF, '_blank');
    }

    return (
        <div className='relative w-screen h-auto md:h-screen'>
            <img src={heroPhoto} alt="Pizzeria el horno del chef" className='w-full h-auto md:h-full object-cover' />
            <div className='absolute inset-0 bg-black opacity-50 w-full h-full'></div>
            <div className='absolute inset-0 flex flex-col items-center justify-center text-white space-y-5 md:space-y-10 p-4 md:p-0'>
                <div className='flex flex-col justify-center items-center'>
                    <p className='font-regular text-xl md:text-3xl text-center'>Bienvenidos a la pizzeria</p>
                    <h1 className='font-bold text-4xl md:text-8xl px-2 md:px-4 text-center'>El Horno Del Chef</h1>
                </div>
                <div className='flex gap-3 md:gap-5'>
                    <Button text='Ver Menú' onClick={handleClick} />
                </div>
            </div>
        </div>
    );
}

export default Hero;
