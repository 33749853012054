import React from 'react';
import PropTypes from 'prop-types';

const CubeRow = ({ image, title, text, orientation }) => {

    console.log(orientation)

  return (
    <div className="flex flex-col h-auto md:flex-row md:h-screen w-full">
      {/* Image Section */}
      <div
        className={`w-full md:w-1/2 h-40 sm:h-64 md:h-auto ${
          orientation === 'left' ? 'sm:order-1' : 'sm:order-2'
        } order-none flex items-center justify-center`}
      >
        <img src={image} alt="Cube" className="object-cover w-full h-full" />
      </div>
      {/* Text Section: Agregar un titulo */}
      <div
        className={`bg-gray-800 w-full md:w-1/2 p-2 sm:p-4 md:p-8 flex flex-col items-center md:items-start justify-center text-center md:text-left ${
          orientation === 'left' ? 'sm:order-2' : 'sm:order-1'
        } order-none`}
      >
        <h2 className='text-gray-100 text-xl sm:text-2xl md:text-3xl mb-2 sm:mb-4 font-bold'>{title}</h2>
        <p className="text-gray-100 text-base sm:text-lg md:text-2xl">{text}</p>
      </div>
    </div>
  );
};

CubeRow.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  orientation: PropTypes.oneOf(['left', 'right']).isRequired,
};

export default CubeRow;
