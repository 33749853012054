import React from 'react';
import menuImage from '../assets/menu.webp';
import Navbar from '../sections/Navbar';

const Menu = () => {
    return (
        <div>
            <Navbar />
            <img src={menuImage} alt="Menu" className="w-full" />
        </div >
    );
};

export default Menu;