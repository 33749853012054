import { useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';

const Navbar = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <nav className="bg-black text-white p-4">
      <div className="container mx-auto flex justify-center">
        {/* Logo */}
        <div className="text-lg h-[70px] font-bold">
          {/* <img src={Logo} alt="Logo" className="h-[70px] cursor-pointer" onClick={handleClick} /> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
