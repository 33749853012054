import React from 'react';
import CubeRow from "../components/CubeRow"
import Pizzas from "../assets/pizzas.jpg"
import Platos from "../assets/platos.png"
import Productos from "../assets/productos.png"

const CubeSection = () => {
    return (
        <div className="flex flex-col">
            <CubeRow 
                image={Pizzas}
                title={"¡Variedad de Pizza para todos los gustos!"}
                text="En nuestra pizzería, ofrecemos una amplia variedad de pizzas, adaptadas para todos los paladares. Ya sea que prefieras algo clásico o algo innovador, tenemos opciones para satisfacer todos los gustos. ¡Ven y descubre tu nueva pizza favorita con nosotros!"
                orientation='left'
            />
            <CubeRow 
                image={Platos}
                title="¡Platos tipicos italianos!"
                text="Además de nuestras deliciosas pizzas, ofrecemos una variedad de platos típicos italianos para degustar. ¡Ven y disfruta de la auténtica cocina italiana con nosotros!"
                orientation='right'
           />
            <CubeRow 
                image={Productos}
                title="¡Venta de productos italianos!"
                text="Ofrecemos una selección de productos típicos importados de Italia, como embutidos y quesos, que también puedes comprar para llevar a casa. ¡Ven y lleva a casa un pedacito de Italia con nuestros productos de alta calidad!"
                orientation='left'
            />
        </div>
    )
}

export default CubeSection;