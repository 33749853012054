import React from 'react';
import legalesPDF from '../assets/legales.pdf';
import privacidadPDF from '../assets/politica-privacidad.pdf';
import cookiesPDF from '../assets/politica-cookies.pdf';
import bannerCookiesPDF from '../assets/banner-cookies.pdf';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-4">
            <div className="container mx-auto text-center space-x-5">
                <a
                    href={legalesPDF}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-gray-400 hover:text-gray-200"
                >
                    Ver legales
                </a>
                <a
                    href={privacidadPDF}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-gray-400 hover:text-gray-200"
                >
                    Política de privacidad
                </a>
                <a
                    href={cookiesPDF}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-gray-400 hover:text-gray-200"
                >
                    Política de Cookies
                </a>
                <a
                    href={bannerCookiesPDF}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-gray-400 hover:text-gray-200"
                >
                    Banner de Cookies
                </a>
            </div>
        </footer>
    );
};

export default Footer;
