import React from "react";

import Hero from '../sections/Hero'
import Navbar from '../sections/Navbar';
import TextSection from '../sections/TextSection';
import GallerySection from '../sections/GallerySection';
import BannerSection from '../sections/BannerSection';
import ContactSection from '../sections/ContactSection';
import BannerPhoto from '../assets/bottom-banner.png'
import PizzaBanner from '../assets/banner-real-photo.jpeg'
import CubeSection from "../sections/CubeSection"
import Footer from '../sections/Footer';

const Home = () => {
    return (
        <div className="w-[100vw] overflow-x-hidden">
            <Navbar />
            <Hero />
            <TextSection />
            <GallerySection />
            <BannerSection title='Donde la tradición se encuentra con la innovacion'
                text='En nuestra pizzería, combinamos la auténtica tradición italiana con técnicas culinarias modernas para ofrecerte una experiencia única. Disfruta de nuestras pizzas artesanales, elaboradas con ingredientes frescos y de alta calidad. ¡Ven y descubre el sabor de la innovación!'
                image={BannerPhoto} />
            <CubeSection />
            <BannerSection title='Tu rincon de Italia en cada plato' text='Te invitamos a visitarnos y disfrutar de una experiencia culinaria única. ¡Ven a vernos y no pierdas esta oportunidad de descubrir la auténtica cocina italiana!' image={PizzaBanner} />
            <ContactSection />
            <Footer />
        </div>
    )
}

export default Home;