import React from 'react';

const Gallery = ({ title, text, images, onImageClick }) => {
    const placeholderImages = [
        'https://via.placeholder.com/600x400',
        'https://via.placeholder.com/600x400',
        'https://via.placeholder.com/600x400',
        'https://via.placeholder.com/600x400',
        'https://via.placeholder.com/600x400',
        'https://via.placeholder.com/600x400',
    ];

    const imagesToShow = images.length > 0 ? images : placeholderImages;

    return (
        <div className="max-w-4xl w-full p-4 md:p-6">
            <h2 className="text-xl md:text-2xl font-bold mb-6 md:mb-10 text-center">{title}</h2>
            <p className="mb-4 md:mb-6 text-center text-gray-700 text-sm md:text-base">{text}</p>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4">
                {imagesToShow.slice(0, 6).map((image, index) => (
                    <div
                        key={index}
                        className="relative w-full h-32 md:h-40 overflow-hidden rounded-lg shadow-md cursor-pointer"
                        onClick={() => onImageClick(image)}
                    >
                        <img
                            src={image}
                            alt={`Gallery image ${index + 1}`}
                            className="absolute inset-0 w-full h-full object-cover"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
