import React from 'react';
import ContactForm from '../components/ContactForm';
import InformationCol from '../components/InformationCol';

const ContactSection = () => {
    return (
        <div className="flex flex-col md:flex-row w-full items-center">
            <div className="w-full md:w-1/2 p-4 md:p-6 justify-center text-center">
                <h2 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4">Ponete en contacto</h2>
                <p className="mb-4 sm:mb-6 text-sm sm:text-base">
                    En caso de que tengas cualquier duda, no dudes en contactarnos. Estamos aquí para ayudarte.
                </p>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 justify-center">
                    <div className='w-full sm:w-1/3'>
                        <InformationCol title="Direccion" type='address'
                            text={`Calle Francy y Roca n. 1,
                        35610 Caleta de Fuste.`} />
                    </div>
                    <div className="w-full sm:w-1/3">
                        <InformationCol title="Telefono" type='phone' text='+34 653 066 491' />
                    </div>
                    <div className="w-full sm:w-1/3">
                        <InformationCol title="Horario" type='clock' text='Horario de atencion: Todos los días de 13:00hs a 00:30hs' />
                    </div>
                </div>
            </div>

            <div className="w-full md:w-1/2 p-4 md:p-6">
                <ContactForm />
            </div>
        </div>
    );
};

export default ContactSection;
