import React from 'react';
import InformationCol from '../components/InformationCol';

const TextSection = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center bg-gray-100 py-8 md:py-16">
      <h1 className="text-2xl md:text-4xl font-bold text-gray-800 mb-6 md:mb-10 px-4 text-center">
        El Horno Del Chef, donde la noche se llena de autentico sabor italiano
      </h1>
      <div className="flex flex-col gap-4 md:gap-2 px-10">
        <p className="text-base md:text-lg text-gray-600 text-center max-w-xl md:max-w-5xl">
          ¡Bienvenidos a Pizzería El Horno del Chef! Aquí, cada visita se convierte en una experiencia única, donde el auténtico sabor italiano se encuentra con un servicio excepcionalmente acogedor. En nuestra pizzería, no solo servimos pizzas; creamos momentos memorables que despiertan tus sentidos y te transportan directamente a las calurosas calles de Italia.
        </p>

        <p className='text-base md:text-lg text-gray-600 text-center max-w-xl md:max-w-5xl'>
          Nuestros productos son 100% italianos, elaborados con ingredientes seleccionados cuidadosamente para garantizar la máxima calidad y frescura. Desde la masa, hecha a mano siguiendo las recetas tradicionales, hasta la salsa de tomate, preparada con tomates jugosos y fragantes, cada componente de nuestras pizzas cuenta una historia.
        </p>

        <p className='text-base md:text-lg text-gray-600 text-center max-w-xl md:max-w-5xl'>
          Así que ven a descubrir la magia de El Horno del Chef. Te prometemos que, una vez que pruebes nuestras exquisitas pizzas, querrás volver una y otra vez. ¡Te esperamos con los brazos abiertos!
        </p>
      </div>

      {/* <div className="flex flex-col md:flex-row gap-3 md:gap-5 mt-4 md:mt-5">
        <InformationCol type='food' title='Lorem' text='Lorem Ipsum' />
        <InformationCol type='lab' title='Lorem' text='Lorem Ipsum' />
        <InformationCol type='delivery' title='Lorem' text='Lorem Ipsum' />
      </div> */}

    </div>
  );
};

export default TextSection;
