import React, { useState } from 'react';
import Gallery from '../components/Gallery';

import Pic1 from '../assets/insalatta.png';
import Pic6 from '../assets/tiramisu.png';
import Pic2 from '../assets/mozzarella-fritta.jpg';
import Pic3 from '../assets/Pizza-con-schiacciata-calabrese.jpg';
import Pic5 from '../assets/gal-5.jpg';
import Pic4 from '../assets/gal-6.webp';

import Insalatta from '../assets/mozzarella.png'

const GallerySection = () => {
    const [selectedImage, setSelectedImage] = useState(Pic3);
    const images = [Pic1, Pic2, Pic3, Pic4, Pic5, Pic6];

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    return (
        <section className="flex flex-col h-auto md:flex-row md:h-screen">
            <div className="w-full md:w-1/2 flex items-center justify-center p-2 md:p-4">
                <Gallery
                    images={images}
                    title="Sabores autenticos, pasión italiana"
                    onImageClick={handleImageClick}
                />
            </div>
            <div className="w-full md:w-1/2 h-64 md:h-full">
                <img
                    src={Insalatta}
                    alt="Selected"
                    className="w-full h-full object-cover"
                />
            </div>
        </section>
    );
};

export default GallerySection;
