import React from 'react';
import { FaUtensils, FaCar, FaFlask, FaMapMarkerAlt, FaPhone, FaClock } from 'react-icons/fa';

const InformationCol = ({ type, title, text }) => {
    const getIcon = (type) => {
        switch (type) {
            case 'food':
                return <FaUtensils className="w-16 h-16 mx-auto mb-4 text-red-600" />;
            case 'lab':
                return <FaFlask className="w-16 h-16 mx-auto mb-4 text-red-600" />;
            case 'delivery':
                return <FaCar className="w-16 h-16 mx-auto mb-4 text-red-600" />;
            case 'address':
                return <FaMapMarkerAlt className="w-16 h-16 mx-auto mb-4 text-red-600" />;
            case 'phone':
                return <FaPhone className="w-16 h-16 mx-auto mb-4 text-red-600" />;
            case 'clock':
                return <FaClock className="w-16 h-16 mx-auto mb-4 text-red-600" />;
            default:
                return null;
        }
    };

    const icon = getIcon(type);

    return (
        <div className="p-4">
            {icon}
            <h2 className="text-xl font-semibold text-center mb-2">{title}</h2>
            <p className="text-center text-gray-700 whitespace-pre-line">{text}</p>
        </div>
    );
};

export default InformationCol;
